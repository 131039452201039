
/* __________ Footer __________ */

.footerYes {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-row: 1fr;
    align-items: center;
    justify-items: center;
    background-color: var(--bg-clr-darker);
    /* background-color: #4F90CB; */
    /* outline: 2px dashed lightseagreen; */
    position: sticky;
    /* margin-top: auto; */
    height: 5rem;
    width: 100%;
    bottom: calc-(5rem + 2px);
    border-top: 2px solid var(--brdr-clr);
    box-shadow: 0px -3px 20px var(--bg-clr-darker);
    transition: all var(--time1);
}

.footerNo {
    display: none;
}


.footerItemYes {
    width: 100%;
    text-align: center;
    transition: all var(--time1);
    /* background-color: red; */
}

.footerItemNo {
    display: none;
}

.logoLink {
    height: 1.75rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}