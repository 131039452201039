.Gallery {
    min-height: calc(100% -5rem);
    height: 100%;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    /* outline: 2px solid steelblue; */
    /* background-color: steelblue; */
}

.GalleryContent {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1.5rem;
    text-align: left;
    align-items: start;
    justify-content: center;
    /* background-color: green; */
    /* outline: 1px solid green; */
}

.GalleryContent > div {
    /* for debugging */
    /* outline: 5px solid orange; */
}

.GalleryContent:first-child {
    margin-top: 1.5rem;
}

.GalleryContent:last-child {
    margin-bottom: 1.5rem;
}

.project {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column: 1 / span 3;
    max-height: 19rem;
    align-items: center;
    overflow: hidden;
    /* outline: 5px solid green; */
    /* background-color: greenyellow; */
    &:hover > a > div,
    &:focus > a > div,
    &:active > a > div
    {
        box-shadow: 0 6px 30px rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(3px);
        /* font-size: 2.4rem; */
        transition: 0.5s;
    }
    &:hover > a > img,
    &:focus > a > img,
    &:active > a > img
    {
        transform: scale(1.05);
        transition: 0.5s;
    }
}

.project > a {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    height: 100%;
    max-height: 19rem;
    width: 100%;
    grid-row: 1;
    grid-column: 1 / span 3;
    /* outline: 12px dashed lightseagreen; */
}

.project > a > img {
    height: 100%;
    /* max-height: 20rem; */
    width: 100%;
    object-fit: cover;
    object-position: 50% 7.5%;
    grid-row: 1;
    grid-column: 1 / span 4;
    transition: 0.5s;
    /* outline: 2px dotted yellow; */
}

.projectTitleRight {
    grid-row: 1;
    grid-column: 3;
    height: 100%;
    /* padding: 2rem; */
    font-size: 2.25rem;
    text-align: right;
    font-weight: bold;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 6px 30px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    transition: 0.5s;
}

.projectTitleLeft {
    grid-row: 1;
    grid-column: 1;
    height: 100%;
    /* padding: 2rem; */
    font-size: 2.25rem;
    text-align: left;
    font-weight: bold;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 6px 30px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    transition: 0.5s;
}


/* p {
    outline: 1px solid red;
    margin: 2rem;
} */