/* __________ HEADER __________ */

.headerSplash {
    /* position: sticky;
    top: 0; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    border-bottom: 0px solid black;
    color: #ffffff;
    background-color: var(--bg-clr-darker);
    box-shadow: 0px 0px 0px var(--bg-clr-darker);
    /* background-image: linear-gradient(to right, cadetblue 50%, darkseagreen 50%); */
    /* background-color: cadetblue; */
    transition: all var(--time1);
    z-index: 2;
}

.headerCompact {
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 5rem;
    width: 100%;
    border-bottom: 2px solid var(--brdr-clr);
    color: #ffffff;
    background-color: var(--bg-clr-darker);
    box-shadow: 0px 5px 25px var(--bg-clr-darker);
    /* background-image: linear-gradient(to right, darkseagreen 50%, cadetblue 50%); */
    /* background-color: darkseagreen; */
    /* outline: 2px dashed olive; */
    transition: all var(--time1);
    z-index: 2;

}

/* ____________ LOGO __________ */

#navLogoSplash {
    width: 100%;
    max-width: 30rem;
    /* margin-top: -7rem; */
    /* background-color:maroon; */
    /* outline: 2px dashed brown; */
    z-index: 3;
    transition: all var(--time1);
}

#navLogoCompact {
    position: relative;
    max-width: 8rem;
    /* background-color:maroon; */
    /* outline: 2px dashed brown; */
    z-index: 3;
    transition: all var(--time1);
}

#navLogoBox{
    display: flex;
    flex-direction: column;
    z-index: 3;
    transition: all var(--time1);
    /* outline: 2px dashed green; */
}

.navLogoBoxSplash {
    width: 100%;
    max-width: 30rem;
    margin-bottom: 1rem;
    /* margin-top: -7rem; */
}

.navLogoBoxCompact {
    /* margin-top: -1.4rem; */
    position: relative;
    margin-top: -1.15rem;
    max-width: 6rem;
}

#navLogoText{
    letter-spacing: 0.45ch;
    margin-top: 0.33rem;
    padding-left: .22rem;
    text-align: center;
    /* color: hsl(210, 85%, 60%); */
    transition: all var(--time1);
    /* outline: 2px dashed orange; */
}

.navLogoTextSplash {
    font-weight: 100;
    font-size: 2.5rem;
}

.navLogoTextCompact {
    font-weight: 500;
    font-size: 0.5rem;
}

/*  __________ NAV ___________ */

.navSplash {
    display: flex;
    flex-direction: row;
    margin-top: 2rem;
    transition: all var(--time1);
    z-index: 2;
    /* background-color: blueviolet; */
    /* outline: 2px dashed blueviolet; */
}

.navCompact {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* max-width: 100vw; */
    max-width: 100%;
    margin-top: -3.1rem;
    /* display: none; */
    padding-left: 1rem;
    padding-right: 1rem;
    transition: all var(--time1);
    z-index: 2;
    /* background-color: blueviolet; */
    /* outline: 2px solid blueviolet; */
}

/* __________ NAV BUTTONS __________ */

.navButton {
    font-weight: bold;
    font-size: 1.5rem;
    width: 8.25rem;
    transition: 0.4s;
    /* background-color:steelblue; */
    /* outline: 2px dashed steelblue; */
    /* outline: 1px solid black; */
}

.navButton > a{
    color: #ffffff;
    transition: 0.4s;
    &:hover,
    &:focus {
        color: #666666;
        transition: 0.4s;
    }
}

/* ___________ NAV BUFFERS __________ */

.navBufferSplash {
    width: 0rem;
    transition: all var(--time1);
}

.navBufferCompact {
    width: clamp(0rem, 7.5vw, 9rem);
    /* max-width: 9rem; */
    transition: all var(--time1);
    /* transition: margin 0s; */
}

body.stop-transitions * {
    transition: none !important;
  }

a.active {
  color: #666666;
}