:root {
  --bg-clr-dark: hsla(0, 0%, 12%, 1);
  --bg-clr-darker: hsla(0, 0%, 5%, 1);
  --brdr-clr: hsla(0, 0%, 65%, 1);

  --time1: 0.66s;

  font-family: Inter, sans-serif;
  font-feature-settings: 'liga' 1, 'calt' 1; /* fix for Chrome */
}

@supports (font-variation-settings: normal) {
  :root { font-family: InterVariable, sans-serif; }
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  list-style-position: outside;
  /* width: 100%; */
}

a {
  text-decoration: inherit;
  color: inherit;
}

html, body {
  height: 100%;
  width: 100%;
  background-color: var(--bg-clr-dark);
}

.App {
  text-align: center;
  color: #ffffff;
  /* font-family: ; */
  /* background-color: yellowgreen; */
  /* outline: 2px dashed orange; */
}

#wrapper {
  height: 100%;
  width: 100%;
  /* display: flex;
  flex-direction: column;
  flex: 1; */
  /* background-color: cyan; */
}

#pageContent {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  /* min-height: calc(100vh - 5rem); */
  /* justify-content: center; */
  /* background-color: darkolivegreen; */
  /* outline: 2px dahed lime; */
}