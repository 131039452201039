.cv-card {
    /* outline: 1px solid #00FFFF; */
    /* outline: 1px solid ; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid;
    padding: 1rem;
    margin: 0rem 2rem 4rem 2rem;
    width: 15rem;
    height: 15rem;
    background-color: hsla(0, 0%, 12%, 1);
    box-shadow: 0px 6.5px 25px #000000;
    border-image: linear-gradient(#9d36fa, #00ffff) 30;
}

h1 {
    margin-bottom: 0px;
}

h2{
    margin: 0px;
    font-size: 1.45rem;
}

h3 {
    margin-top: 1rem;
    margin: 0px;
    text-align: left;
}