.about {
    /* background-color: brown; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-height: calc(100vh - 5rem);
    width: 100%;
}

.aboutContent {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    text-align: left;
    align-items: center;
    justify-items: center;
    gap: 2rem;
    /* background-color: green; */
    /* outline: 1px solid green; */
}

.Portrait {
    /* outline: 1px solid black; */
    max-width: 17rem;
    grid-column: 2;
    justify-self: end;
}

.aboutContent > p {
    max-width: 17rem;
    justify-self: start;
}