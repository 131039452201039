@media (width <= 550px) {

    .footerItemYes {
        font-size: 0.8rem;
        margin-top: 0.8rem;
    }

    .logoLink {
        height: 1.4rem;

    }

}