@media (width <= 960px) {

    .cvContent {
        display: flex;
        width: 60%;
        /* max-width: 70rem; */
        height: 100%;
        text-align: left;
        align-items:center;
        justify-content: center;
        margin-top: 2rem;
        /* background-color: green; */
        /* outline: 1px solid green; */
    }

    .filmwork {
        /* outline: 1px solid aqua; */
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-items: center;
        width: 100%;
    }

    .rlHolder {
        max-height: 540rem;
    }

    .responsibilitiesList {
        /* columns: 2; */
        grid-template-rows: repeat(6, 2.5rem);
        grid-template-columns: 19rem 19rem;
    }

    .skills {
        grid-template-areas:
        ". ind ."
        ". des ."
        ". soft ."
        ". gen .";
        text-wrap: balance;
        max-height: 1000000px;
        width: 100%;
    }

    .skills > .list {
        width: 30ch;
    }

    .educationList{
        display: grid;
        grid-template-areas:
            "one"
            "two";
        justify-items: center;
    }

}


@media (width <= 760px) {

    .cvContent {
        display: flex;
        width: 60%;
        max-width: 70rem;
        height: 100%;
        text-align: left;
        align-items:center;
        justify-content: center;
        margin-top: 2rem;
        /* background-color: green; */
        /* outline: 1px solid green; */
    }

    .cvSection > div {
        /* outline: 1px solid aqua; */
        display: grid;
        grid-template-columns: 1fr;
        /* gap: 2rem; */
    }

    .responsibilitiesList {
        /* columns: 1; */
        grid-template-rows: repeat(10, 2.5rem );
        grid-template-columns: 19rem;
        margin-bottom: 4rem;
    }

    .skills {
        grid-template-areas:
        "ind"
        "des"
        "soft"
        "gen";
        text-wrap: balance;
        max-height: 1000000px;
        width: 100%;
    }

    .skills > .list {
        width: 28ch;
    }

    /* .skills{
        display: grid;
    } */

    .designSkillsList {
        line-height: 2.5rem;
    }

    .generalSkillsList {
        line-height: 2.5rem;
    }

}