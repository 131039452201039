@media (width <= 960px) {
    .aboutContent {
        grid-template-columns: 1fr 1fr 2fr 1fr;
    }
}


@media (width <= 760px) {
    .projectTitleRight {
        font-size: 1.5rem;
    }
    .projectTitleLeft {
        font-size: 1.5rem;
    }
}