h3 {
    margin-top: 0;
    text-align: center;
}

.cv {
    height: 100%;
    min-height: calc(100vh - 5rem);
    width: 100%;
    margin-bottom: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    /* background-color: brown; */
}

.cvContent {
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 100%;
    text-align: left;
    align-items:center;
    justify-content: center;
    margin-top: 5rem;
    /* background-color: green; */
    /* outline: 1px solid green; */
}

.cvSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 0 0 100%;
    min-width: 100%;
}

.cvSection > h1 {
    text-align:center ;
    font-size: 2rem;
    margin-bottom: 4rem;
}

.cvSubheading {
    margin-bottom: 1.5rem;
    text-align: center;
}

/* ----------------------------------------------------------------------------- */


.filmworkText {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-wrap: balance;
    width: 100%;
    /* outline: 1px solid orange; */
}

.rlHolder {
    display: flex;
    width: 100%;
    max-height: 12rem;
    padding: 1rem;
    justify-content: center;
    overflow:hidden;
}

.responsibilitiesList {
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: 2.5rem 2.5rem 2.5rem 2.5rem ;
    grid-template-columns: 19rem 17rem 19rem;
    /* ^This is hacky as hell but I'm fed up trying to make a more elagent solution work to automatically allow for different width columns. Doing this manually at least looks good for now even if it will be janky to add to.  */
    text-align: left;
    line-height: 2rem;
    margin-bottom: 3rem;
}

.responsibilitiesList > li {
    list-style-position: inside;
    text-indent: -1em;
    padding-left: 1em;
    margin: 0 3rem 0 3rem;
    /* background-color: aqua; */
}

.filmwork {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    max-width: 85rem;
    /* max-width: 100%; */
    /* outline: 1px solid aqua; */
}

/* ----------------------------------------------------------------------------- */

.skills {
    display: grid;
    grid-template-areas:
        ". ind  gen ."
        ". soft des .";
    justify-items: center;
    text-wrap: balance;
    width: 100%;
}

.skills > .list {
    /* margin: 1rem 2.5rem 1rem 2.5rem; */
    width: 40ch;
    margin-bottom: 2.5rem;
    /* outline: 1px solid red; */
}

/* ----------------------------------------------------------------------------- */

.education {
    display: flex;
    flex-direction: column;
    justify-items: center;
    text-wrap: balance;
    width: 100%;
    /* outline: 1px dashed orange; */
}

.education > label {
    align-self: center;
}

.educationList{
    display: grid;
    grid-template-areas:
        ". one two .";
    justify-items: center;
}

.educationList > ul {
    /* outline: 1px solid lime; */
    max-width: 40ch;
    margin-top: 1.5rem;

}

/* ----------------------------------------------------------------------------- */

.list {
    flex-direction: column;
    line-height: 2.5rem;
    text-shadow: 0px 3px 4px #000000;
    break-inside: avoid;
    list-style-type: disc;
    text-wrap: balance;
    /* max-width: 40ch; */
    /* padding-left: 1.5ch; */
    /* outline: 1px solid green; */
}

/* ------------------------------------------------- */

.industrySkillsList{
    grid-area: ind;
}

.softwareExperienceList{
    grid-area: soft;
}

.designSkillsList {
    grid-area: des;
}

.generalSkillsList {
    grid-area: gen;
}

/* -------------------------------------------------- */

.educationList1{
    grid-area: one;
}

.educationList2{
    grid-area: two;
}